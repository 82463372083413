import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
// import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import ClickWall from '../components/sections/click-wall'

const ThankYou = ({ location }) => (
  <Layout phone="888-996-1365" location={location}>
    <SEO title="Auto Insurance" keywords={[`auto`, `insurance`, `plans`, `affordable`]} />
    <Header phone="888-996-1365" hideCta showGen grayLogo />
    <main id="main">
      <ClickWall />
      <Partners />
    </main>
  </Layout>
)

export default ThankYou
